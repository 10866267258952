import type { ManagerPartner, ManagerPartnerServer, PartnerLink, PartnerLinkServer, PartnerPromocode, PartnerPromocodeServer, PartnerStats, PartnerStatsServer, PlayerStats, PlayerStatsServer, PromocodeScript, PromocodeScriptServer } from './types';


export const PPAdapter = {
  partnerStatsToClient: (stats: PartnerStatsServer): PartnerStats => ({
    deposits: stats.deposits,
    adminDeposits: stats.admin_deposits,
    betsPlaced: stats.bets_placed,
    betsWinnings: stats.bets_winnings,
    betsLosses: stats.bets_losses,
    betsIngame: stats.bets_ingame,
    betsIncome: stats.bets_income,
    inbetPlaced: stats.inbet_placed,
    inbetWinnings: stats.inbet_winnings,
    inbetIncome: stats.inbet_income,
    vegasPlaced: stats.vegas_placed,
    vegasWinnings: stats.vegas_winnings,
    vegasIncome: stats.vegas_income,
    tvbetPlaced: stats.tvbet_placed,
    tvbetWinnings: stats.tvbet_winnings,
    tvbetIncome: stats.tvbet_income,
    pragmaticPlaced: stats.pragmatic_placed,
    pragmaticWinnings: stats.pragmatic_winnings,
    pragmaticIncome: stats.pragmatic_income,
    mascotPlaced: stats.mascot_placed,
    mascotWinnings: stats.mascot_winnings,
    mascotIncome: stats.mascot_income,
    betgamesPlaced: stats.betgames_placed,
    betgamesWinnings: stats.betgames_winnings,
    betgamesIncome: stats.betgames_income,
    agtPlaced: stats.agt_placed,
    agtWinnings: stats.agt_winnings,
    agtIncome: stats.agt_income,
    aviatrixIncome: stats.aviatrix_income,
    aviatrixPlaced: stats.aviatrix_placed,
    aviatrixWinnings: stats.aviatrix_winnings,
    endorphinaIncome: stats.endorphina_income,
    endorphinaPlaced: stats.endorphina_placed,
    endorphinaWinnings: stats.endorphina_winnings,
    fivemenIncome: stats.fivemen_income,
    fivemenPlaced: stats.fivemen_placed,
    fivemenWinnings: stats.fivemen_winnings,
    income: stats.income,
    numClicks: stats.num_clicks,
    numRegistrations: stats.num_registrations,
    firstDeposits: stats.first_deposits,
    revshare: stats.revshare,
    subpartnersRevshare: stats.subpartners_revshare,
    currentIncome: stats.current_income,
  }),
  playerStatsToClient: (stats: PlayerStatsServer): PlayerStats => ({
    deposits: stats.deposits,
    adminDeposits: stats.admin_deposits,
    betsPlaced: stats.bets_placed,
    betsWinnings: stats.bets_winnings,
    betsLosses: stats.bets_losses,
    betsIngame: stats.bets_ingame,
    betsIncome: stats.bets_income,
    inbetPlaced: stats.inbet_placed,
    inbetWinnings: stats.inbet_winnings,
    inbetIncome: stats.inbet_income,
    vegasPlaced: stats.vegas_placed,
    vegasWinnings: stats.vegas_winnings,
    vegasIncome: stats.vegas_income,
    tvbetPlaced: stats.tvbet_placed,
    tvbetWinnings: stats.tvbet_winnings,
    tvbetIncome: stats.tvbet_income,
    pragmaticPlaced: stats.pragmatic_placed,
    pragmaticWinnings: stats.pragmatic_winnings,
    pragmaticIncome: stats.pragmatic_income,
    mascotPlaced: stats.mascot_placed,
    mascotWinnings: stats.mascot_winnings,
    mascotIncome: stats.mascot_income,
    betgamesPlaced: stats.betgames_placed,
    betgamesWinnings: stats.betgames_winnings,
    betgamesIncome: stats.betgames_income,
    agtPlaced: stats.agt_placed,
    agtWinnings: stats.agt_winnings,
    agtIncome: stats.agt_income,
    aviatrixIncome: stats.aviatrix_income,
    aviatrixPlaced: stats.aviatrix_placed,
    aviatrixWinnings: stats.aviatrix_winnings,
    endorphinaIncome: stats.endorphina_income,
    endorphinaPlaced: stats.endorphina_placed,
    endorphinaWinnings: stats.endorphina_winnings,
    fivemenIncome: stats.fivemen_income,
    fivemenPlaced: stats.fivemen_placed,
    fivemenWinnings: stats.fivemen_winnings,
    income: stats.income,
    playerId: stats.player_id,
    playerName: stats.player_name,
  }),
  linkToClient: (link: PartnerLinkServer): PartnerLink => ({
    refCode: link.ref_code,
    created: link.created,
    projectId: link.project_id,
    projectName: link.project_name,
    link: link.link,
    title: link.title,
  }),
  promocodeScriptToClient: (script: PromocodeScriptServer): PromocodeScript => ({
    description: script.description,
    conditionType: script.condition_type,
    balanceType: script.balance_type,
    amountType: script.amount_type,
    isPrivate: script.is_private,
    currencyData: script.currency_data.map((item) => ({
      currency: item.currency,
      amount: item.amount,
      maxSum: item.max_sum,
    })),
    id: script.id,
  }),
  promocodeToClient: (script: PartnerPromocodeServer): PartnerPromocode => ({
    id: script.id,
    created: script.created,
    name: script.name,
    scriptId: script.script_id,
    scriptDescription: script.script_description,
  }),
  managerPartnerToClient: (user: ManagerPartnerServer): ManagerPartner => ({
    id: user.id,
    login: user.login,
    email: user.email,
    phone: user.phone,
    phoneCountry: user.phone_country,
    name: user.name,
    role: user.role,
    created: user.created,
  })
};
