export function getCurrencyByCountry(countryCode: string) {
  switch (countryCode) {
    case 'AZ': return 'AZN'; // Azerbaijan
    case 'RU': return 'RUB'; // Russia
    case 'KZ': return 'KZT'; // Kazakhstan
    case 'UA': return 'UAH'; // Ukraine
    case 'TJ': return 'TJS'; // Tajikistan
    case 'UZ': return 'UZS'; // Uzbekistan
    case 'KG': return 'KGS'; // Kyrgyzstan
    case 'TM': return 'TMT'; // Turkmenistan
    case 'AM': return 'AMD'; // Armenia
    case 'BY': return 'BYN'; // Belarus
    case 'MD': return 'MDL'; // Moldova
    case 'GE': return 'GEL'; // Georgia
    case 'KE': return 'KES'; // Kenya
    // European countries using Euro
    case 'AL':
    case 'AD':
    case 'AT':
    case 'BE':
    case 'CY':
    case 'EE':
    case 'FI':
    case 'FR':
    case 'DE':
    case 'GR':
    case 'IE':
    case 'IT':
    case 'LV':
    case 'LT':
    case 'LU':
    case 'MT':
    case 'MC':
    case 'ME':
    case 'NL':
    case 'PT':
    case 'SM':
    case 'SK':
    case 'SI':
    case 'ES':
    case 'VA':
    case 'EU':
      return 'EUR'; // European Union and countries using Euro
    default:
      return 'UAH'; // Default to USD
  }
}
