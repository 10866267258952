import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

import bannerNew1 from 'assets/banners/bannerNew1.jpg';
import bannerNew5 from 'assets/banners/bannerNew5.jpg';
import bannerUA1 from 'assets/banners/bannerUA1.jpg';
import bannerUA2 from 'assets/banners/bannerUA2.jpg';
import bannerKZ1 from 'assets/banners/bannerKZ1.jpg';
import bannerDesktop1 from 'assets/banners/desktop/bannerDesktop1.jpg';
import bannerDesktop2 from 'assets/banners/desktop/bannerDesktop2.jpg';
import bannerDesktopUA1 from 'assets/banners/desktop/bannerDesktopUA1.jpg';
import bannerDesktopUA2 from 'assets/banners/desktop/bannerDesktopUA2.jpg';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectIsAuthenticated } from 'store/auth/selectors';
import { selectAccountLoading, selectPrimaryWalletCurrency, selectUserGeo } from 'store/user/selectors';
import { useGetWalletsQuery } from 'store/user/userApi';
import { changeActiveModal } from 'store/auth/authSlice';
import { useGetBannersQuery } from 'store/app/appApi';
import { transformBanner } from 'utils/tranfromBanner';
import { getCurrencyByCountry } from 'utils/getCurrencyByGeo';

export type BannerType = {
  img: string;
  webp?: string;
  link?: string;
  href?: string;
}

type desktopList = {
  big: BannerType[];
  small: BannerType[];
}

const Banner: Record<string, BannerType> = {
  bannerNew1: {
    img: bannerNew1,
  },
  bannerNew5: {
    img: bannerNew5,
  },
  bannerUA1: {
    img: bannerUA1,
  },
  bannerUA2: {
    img: bannerUA2,
  },
  bannerKZ1: {
    img: bannerKZ1,
  },

  bannerDesktop1: {
    img: bannerDesktop1,
  },
  bannerDesktop2: {
    img: bannerDesktop2,
  },
  bannerDesktopUA1: {
    img: bannerDesktopUA1,
  },
  bannerDesktopUA2: {
    img: bannerDesktopUA2,
  },
};

const BannersListDesktop: Record<string, { big: BannerType[]; small: BannerType[] }> = {
  'DEFAULT': {
    big: [Banner.bannerDesktopUA1],
    small: [Banner.bannerDesktopUA2],
  },
  'RU': {
    big: [Banner.bannerDesktop1],
    small: [Banner.bannerDesktop2],
  },
  'RUB': {
    big: [Banner.bannerDesktop1],
    small: [Banner.bannerDesktop2],
  },
  'UA': {
    big: [Banner.bannerDesktopUA1],
    small: [Banner.bannerDesktopUA2],
  },
  'UAH': {
    big: [Banner.bannerDesktopUA1],
    small: [Banner.bannerDesktopUA2],
  },
};

export const useLocalizedBanners = () => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isAccaountLoading = useAppSelector(selectAccountLoading);
  const userGeo = useAppSelector(selectUserGeo);
  const userCurrency = useAppSelector(selectPrimaryWalletCurrency);
  const wallets = useGetWalletsQuery(undefined, { skip: !isAuthenticated || isAccaountLoading });
  const { data: banners = [], isLoading: bannersLoading } = useGetBannersQuery();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSlideClick = (link: string) => {
    if (isAuthenticated) {
      navigate(link);
      return;
    }
    dispatch(changeActiveModal('sign-up'));
  };

  const sortedBanners = [...banners].sort((a, b) => b.priority - a.priority);

  const defaultMobList = transformBanner(
    sortedBanners.filter((banner) =>
      !banner.isDesktop && banner.currency === 'UAH')
  );

  const defaultDeskBigList = transformBanner(
    sortedBanners.filter((banner) =>
      banner.isDesktop && banner.currency === 'UAH')
  );

  const banersListMobile: BannerType[] = useMemo(() =>
    transformBanner(
      sortedBanners.filter((banner) => (
        !banner.isDesktop &&
        (
          banner.currency === null ||
          banner.currency === (userCurrency ?? getCurrencyByCountry(userGeo.country ?? 'US'))
        )
      ))
    ), [sortedBanners, userCurrency, userGeo.country]);

  if (banersListMobile.length <= 0) {
    banersListMobile.push(...defaultMobList);
  }

  const banersListDesktop: desktopList = useMemo(() => ({
    big: transformBanner(
      sortedBanners.filter((banner) => (
        banner.isDesktop &&
        (
          banner.currency === null ||
          banner.currency === (userCurrency ?? getCurrencyByCountry(userGeo.country ?? 'US'))
        )
      ))
    ),
    small: BannersListDesktop[userCurrency as string]?.small
      || BannersListDesktop.DEFAULT.small,
  }), [sortedBanners, userCurrency, userGeo.country]);

  if (banersListDesktop.big.length <= 0) {
    banersListDesktop.big.push(...defaultDeskBigList);
  }


  return {
    isLoading: isAccaountLoading || userGeo.isLoading || wallets.isLoading || bannersLoading,
    bannersMobile: banersListMobile,
    bannersDesktop: banersListDesktop,
    onSlideClick
  };
};
