import { Link, NavLink, Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PPLayout from '../components/PPLayout/PPLayout';
import PPManagerStats from './widgets/PPManagerStats';
import PPManagerPartners from './widgets/PPManagerPartners';
import PPManagerPartnerPlayers from './widgets/PPManagerPartnerPlayers';
import PPManagerPartnerLinkStats from './widgets/PPManagerPartnerLinkStats';
import PPManagerPartnerLinkPlayers from './widgets/PPManagerPartnerLinkPlayers';
import PPManagerPartnerPromocodeStats from './widgets/PPManagerPartnerPromocodeStats';
import PPManagerPartnerPromocodePlayers from './widgets/PPManagerPartnerPromocodePlayers';

import type { TMenuItem } from 'store/partnerProgram/types';

import './PPManager.scss';

const setActive = (props: { isActive: boolean; isPending: boolean }) => props.isActive ? 'pp--active-link' : '';

const PPManager = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'partner-program' });
  const menuItems: TMenuItem[] = [
    {
      key: 'stats',
      label: <NavLink to="/partner-program" className={setActive} end>{t('stats', 'Статистика')}</NavLink>,
    },
    {
      key: 'partners',
      label: <NavLink to="/partner-program/partners" className={setActive}>{t('partners', 'Партнёры')}</NavLink>,
    },
  ];

  return (
    <PPLayout
      title={t('pp', 'Партнёрская программа') ?? ''}
      role={t('manager', 'Менеджер')}
      menuItems={menuItems}
    >
      <Routes>
        <Route index element={<PPManagerStats />} />
        <Route path="partners" element={<PPManagerPartners />} />
        <Route path="partners/:id/players" element={<PPManagerPartnerPlayers />} />
        <Route path="partners/links/:refcode" element={<PPManagerPartnerLinkStats />} />
        <Route path="partners/links/:refcode/players" element={<PPManagerPartnerLinkPlayers />} />
        <Route path="partners/promocodes/:id" element={<PPManagerPartnerPromocodeStats />} />
        <Route path="partners/promocodes/:id/players" element={<PPManagerPartnerPromocodePlayers />} />
        <Route path="*" element={<Navigate to="/partner-program" />} />
      </Routes>
    </PPLayout>
  );
};

export default PPManager;
